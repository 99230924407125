\
<template>
  <Page>
    <!-- Warnings -->
    <danger
      class="xs error-card mt-8"
      :flat="true"
      :rounded="false"
      :hideTitle="true"
      :noBorder="true"
      v-if="requirements.counterpartyStatus === 's'"
    >
      <span
        >Your business account has not been verified and therefore you cannot receive payments
        yet.</span
      >
    </danger>
    <warning
      class="xs error-card mt-8"
      :flat="true"
      :rounded="false"
      :hideTitle="true"
      :noBorder="true"
      v-else-if="requirements.counterpartyStatus === '#'"
    >
      <span
        >Payouts and transfers are disabled for this account until the account is verified. To
        receive payouts please contact support for further details.</span
      >
    </warning>

    <PageHeader title="Business Accounts" />
    <Skeleton v-if="bodyLoading" height="4rem" />
    <Skeleton v-if="bodyLoading" height="4rem" />
    <Skeleton v-if="bodyLoading" height="4rem" />
    <TabView :scrollable="true">
      <!-- Activities -->
      <TabPanel v-if="!bodyLoading">
        <template #header> Activities </template>
        <BusinessAccountsActivities
          :accounts="bankAccounts"
          :merchant="merchant"
          :owners="owners"
          :counterparty="counterparty"
        />
      </TabPanel>

      <!-- Required Actions -->
      <TabPanel v-if="!bodyLoading">
        <template #header>
          Required Actions
          <font-awesome-icon
            v-if="badgeRequirementCount"
            :icon="['fas', 'circle-exclamation']"
            class="text-deep-red-500 text-xs self-start ml-1"
          />
        </template>
        <!-- TODO: filter out non-business account requirements, same for payments -->
        <CounterpartyRequirements
          :merchant="merchant"
          :owners="owners"
          :counterparty="counterparty"
          :requirements="requirements"
        />
      </TabPanel>
    </TabView>
  </Page>
</template>

<script>
import BusinessAccountsActivities from './BusinessAccountsActivities.vue'
import CounterpartyRequirements from './CounterpartyRequirements.vue'
import CounterpartyMixin from '../CounterpartyMixin'
import PageHeader from '@/components/layout/page/PageHeader.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'

import PayfacMixin from '../../PayfacMixin'
import Skeleton from 'primevue/skeleton'

export default {
  name: 'CounterpartyDashboard',
  data() {
    return {
      tab: ['Activity'],
      bodyLoading: false,
      owners: [],
      counterparty: {},
      merchant: {},
      bankAccounts: {},
      requirements: {},
      ownerErrors: []
    }
  },
  /**
   * Init loading the counterparty details
   */
  async created() {
    this.bodyLoading = true
    // first fetch the full payee
    const { payload } = await this.fetchPayee()
    // includes sub merchant data (external details), counterparty (our entity) and bank accounts
    const { merchant, counterparty, bankAccounts, owners } = payload
    this.merchant = merchant
    this.counterparty = counterparty
    this.bankAccounts = bankAccounts
    this.owners = owners
    // Now fetch and see if there are any requirements to complete merchant KYC
    const { payload: requirements } = await this.payeeRequirements()
    this.requirements = requirements
    this.bodyLoading = false
    this.ownerErrors = this.owners.reduce((acc, owner) => [...acc, ...owner.errors], [])
  },
  computed: {
    /**
     * Tally the requirements to display as badge count
     */
    badgeRequirementCount() {
      return this.requirements &&
        (this.requirements.paymentRequirements.length > 0 ||
          this.requirements.payoutRequirements.length > 0)
        ? [...this.requirements.paymentRequirements, ...this.requirements.payoutRequirements].length
        : null
    }
  },
  mixins: [CounterpartyMixin, PayfacMixin],
  methods: {
    /**
     * When clicking the warning above the dashboard to add a new
     * bank account switch to that tab
     */
    addExternalAccount() {
      this.tab = ['Activity']
      setTimeout(() => {
        c.scrollTo(document.querySelector('#bankAccounts'))
      }, 600)
    }
  },
  components: {
    Skeleton,
    BusinessAccountsActivities,
    CounterpartyRequirements,
    PageHeader,
    TabView,
    TabPanel
  }
}
</script>

<style lang="scss" rel="stylesheet/scss">
.sidepanel-container.full-width {
  .cc-container.container-element {
    max-width: 100% !important;
  }
}

.error-card {
  min-height: 64px;
}
</style>
