<template>
  <Page>
    <!-- Company has access to business accounts has onboarded -->
    <div v-if="!loading">
      <BusinessAccountsDashboard v-if="hasAccess" :status="status" />

      <template v-else>
        <!-- Company does not have access to business Accounts -->
        <container class="my-4 py-4" v-if="status === 'b'">
          <card-section>
            <card :flat="true">
              <div class="flex flex-col items-center w-100 text-center py-5">
                <h3 class="mb-3">Business account not available</h3>
                <p>
                  You do not currently have access to business accounts in your area. <br />
                  Contact support to request access.
                </p>
              </div>
            </card>
          </card-section>
        </container>

        <!-- Company has access to business accounts but has not onboarded -->
        <template v-else-if="status === 'u'">
          <container>
            <div class="bg-white p-0 shadow-xl lg:mx-8 my-3">
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div class="flex flex-col justify-content-between max-w-xl py-14 px-12">
                  <h1 class="bg-level-yellow text-pitch-black p-3 leading-10">
                    Strengthen Your Payment Process with Bolster
                  </h1>
                  <p class="text-lg text-pitch-black mt-4">
                    With Bolster's business accounts feature, you can expect a seamless, efficient,
                    and secure payment experience that empowers you to focus on what you do
                    best—building.
                  </p>
                  <div class="flex mt-4">
                    <font-awesome-icon class="text-2xl mt-1" icon="shield-check" />
                    <div class="ml-6">
                      <p class="text-pitch-black mb-1">Quicker Settlement</p>
                      <p class="text-pitch-black">
                        Get your cash quicker with our fastest settlement options. No more delays or
                        cash flow interruptions—just fast, reliable access to your hard-earned
                        funds.
                      </p>
                    </div>
                  </div>

                  <div class="flex mt-4">
                    <font-awesome-icon class="text-2xl mt-1" icon="coin-front" />
                    <div class="ml-6">
                      <p class="text-pitch-black mb-1">Time-Saving Automation</p>
                      <p class="text-pitch-black">
                        Say goodbye to manual tasks. There's no need to write checks or hand-deliver
                        them to your banks or subcontractors. Funds are sent online and can be
                        deposited immediately.
                      </p>
                    </div>
                  </div>

                  <div class="flex mt-4">
                    <font-awesome-icon class="text-2xl mt-1" icon="money-bill-transfer" />
                    <div class="ml-6">
                      <p class="text-pitch-black mb-1">Convenient Payment Options</p>
                      <p class="text-pitch-black">
                        Make it easy for your clients to settle invoices promptly, improving your
                        cash flow and reducing administrative hassle. Give your clients the
                        flexibility to pay from anywhere, at any time, on their devices.
                      </p>
                    </div>
                  </div>

                  <div class="flex mt-4">
                    <font-awesome-icon class="text-2xl mt-1" icon="file-invoice-dollar" />
                    <div class="ml-6">
                      <p class="text-pitch-black mb-1 font-dark">
                        Integrated Billing & Collections
                      </p>
                      <p class="text-pitch-black">
                        Streamline your billing and collections process by centralizing it in one
                        place. Easily manage your transactions within the platform, avoiding
                        disruption and keeping organized.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="bg-pitch-black h-full rounded-r-lg flex flex-col justify-center">
                  <h1 class="mt-4 text-lg !text-white px-12 text-center">
                    Transform the way you handle payments, saving time, reducing stress, and
                    improving your bottom line.
                  </h1>
                  <div class="grid grid-cols-2 gap-4 pt-8 px-12">
                    <Btn @click="toggleOnboarding" size="lg" severity="primary-yellow">
                      Get started
                    </Btn>

                    <Btn
                      @click="() => $store.dispatch('helpTopic', 'payments')"
                      size="lg"
                      severity="secondary"
                    >
                      Connect with us
                    </Btn>
                  </div>
                </div>
              </div>
            </div>
          </container>
        </template>
      </template>
    </div>
    <CompanyOnboarding />
  </Page>
</template>

<script>
import PageMixin from '../mixins/Page'
import CounterpartyMixin from '../../components/payments/counterparty/CounterpartyMixin'
import BusinessAccountsDashboard from '../payments/counterparty/dashboard/BusinessAccountsDashboard.vue'
import CompanyOnboarding from '../onboarding/CompanyOnboarding.vue'
import eventBus from '../../eventBus'

export default {
  name: 'Payments',
  mixins: [PageMixin, CounterpartyMixin],
  data() {
    return {
      loading: 1,
      status: 'b'
    }
  },
  methods: {
    toggleOnboarding() {
      eventBus.$emit('toggleCompanyOnboarding')
    }
  },
  async mounted() {
    this.loading = 1
    this.status = await this.getPayeeStatus()
    this.loading = 0
  },
  computed: {
    hasAccess() {
      return this.status !== 'b' && this.status !== 'u'
    }
  },
  components: {
    BusinessAccountsDashboard,
    CompanyOnboarding
  }
}
</script>

<style scoped></style>
