<template>
  <card-section class="p-0">
    <Info
      v-if="
        payoutInterval === 'regular' && counterparty.counterparty_status === 'a' && isPayfacEnabled
      "
      class="mb-4 info"
      :flat="true"
    >
      <div class="flex items-center w-100">
        You qualify for faster settlement times. Select the settlement time you would like to switch
        to.
        <choose
          :value="payoutInterval"
          class="ml-4"
          btnClass="info sm flat"
          v-model="payoutInterval"
          @input="(val) => onChangeSettlementInterval(val)"
          :static-set="settlementTimes"
        />
      </div>
    </Info>

    <div class="flex flex-col xl:justify-between xl:flex-row">
      <div>
        <div class="flex flex-row items-center">
          <h2>
            {{ $store.state.session.company.company_name }}
          </h2>
          <Badge :class="['ml-2 px-2', getStatusClass(merchant.status)]" :value="merchant.status" />
        </div>
        <div class="mt-2" v-if="owners && owners.length > 0">
          <p class="flex">
            {{ owners[0].first_name }} {{ owners[0].last_name }}
            <Help class="text-sm ml-2">
              <template #title> Account Owner </template>
              <p>
                This is the primary individual that is currently associated with the company's
                payment account.
              </p>
            </Help>
          </p>
        </div>
      </div>

      <div
        v-if="counterparty.counterparty_status === 'a'"
        class="flex flex-col gap-4 mt-4 mt-md-0 xl:flex-row xl:items-center"
      >
        <span class="flex flex-row items-center gap-2">
          <span> Auto payouts: </span>
          <toggle
            @change="() => updatePayoutSchedule(autoPayouts)"
            :value="autoPayouts"
            v-model="autoPayouts"
            :onValue="true"
            :offValue="false"
          />
        </span>
        <span v-if="isPayfacEnabled" class="flex flex-row items-center">
          <span> Settlement time: </span>
          <choose
            :value="payoutInterval"
            class="ml-2"
            v-model="payoutInterval"
            @input="(val) => onChangeSettlementInterval(val)"
            :static-set="settlementTimes"
          />
        </span>
        <btn :disabled="autoPayouts" @click="createPayout" class="btn sm md info py-2">
          Withdraw money
        </btn>
      </div>
    </div>

    <h3 class="mt-4 ml-1 !mb-1">Balances</h3>
    <CounterpartyBalances :balances="defaultBalance" :loading="loading" />

    <h3 class="mt-4 ml-1 !mb-1">Business Account</h3>
    <Card>
      <TabView
        @on-select="(tab) => onSelect('paymentCurrentTab', tab)"
        class="min-h-[300px]"
        :tabs="paymentTabs"
        :fill="true"
        :default="paymentCurrentTab"
      >
        <TabPanel :header="isPayfacEnabled ? 'Payment' : 'Attempts'">
          <TransactionList
            v-if="balanceAccountIsReady"
            :reload="reload"
            defaultFilter="succeeded"
            :filters="
              isPayfacEnabled
                ? []
                : [
                    {
                      text: 'Canceled',
                      value: 'canceled'
                    },
                    {
                      text: 'Processing',
                      value: 'processing'
                    },
                    {
                      text: 'Requires Confirmation',
                      value: 'requires_action'
                    },
                    {
                      text: 'Requires Action',
                      value: 'requires_confirmation'
                    },
                    {
                      text: 'Requires Payment Method',
                      value: 'requires_payment_method'
                    },
                    {
                      text: 'Requires Capture',
                      value: 'requires_capture'
                    },
                    {
                      text: 'Succeeded',
                      value: 'succeeded'
                    }
                  ]
            "
            type="intent"
            :balanceAccount="defaultBalanceAccount"
          />
        </TabPanel>
        <TabPanel header="Charges" v-if="!isPayfacEnabled">
          <TransactionList
            v-if="balanceAccountIsReady"
            :reload="reload"
            defaultFilter="succeeded"
            :filters="[
              {
                text: 'Failed',
                value: 'failed'
              },
              {
                text: 'Pending',
                value: 'pending'
              },
              {
                text: 'Succeeded',
                value: 'succeeded'
              }
            ]"
            type="charge"
          />
        </TabPanel>
        <TabPanel header="Payouts">
          <TransactionList
            v-if="balanceAccountIsReady"
            :reload="reload"
            defaultFilter="paid"
            :filters="
              isPayfacEnabled
                ? []
                : [
                    {
                      text: 'Paid',
                      value: 'paid'
                    },
                    {
                      text: 'Pending',
                      value: 'pending'
                    },
                    {
                      text: 'Failed',
                      value: 'failed'
                    },
                    {
                      text: 'Canceled',
                      value: 'canceled'
                    }
                  ]
            "
            type="payout"
            :balanceAccount="defaultBalanceAccount"
          />
        </TabPanel>
        <!--        <TabPanel header="Business Account Statements" class="overflow-x-auto scrollbar-hide">-->
        <!--          <TransactionList-->
        <!--            v-if="balanceAccountIsReady"-->
        <!--            :reload="reload"-->
        <!--            defaultFilter="paid"-->
        <!--            :filters="[-->
        <!--              {-->
        <!--                text: 'BalanceAccountId',-->
        <!--                value: '123'-->
        <!--              }-->
        <!--            ]"-->
        <!--            type="balanceAccount"-->
        <!--            :balanceAccount="defaultBalanceAccount"-->
        <!--          />-->

        <!--          <div class="max-w-4xl mx-auto p-4">-->
        <!--            <header class="text-center mb-4">-->
        <!--              <h1 class="text-2xl font-bold">Bolster Built Inc.</h1>-->
        <!--              <p class="text-lg">Statement Period: June 1 - June 30</p>-->
        <!--              <p class="text-lg">Balance: $24,500</p>-->
        <!--              <p>1920 McKinney Ave, Dallas, TX 75201, USA</p>-->
        <!--            </header>-->

        <!--            <section>-->
        <!--              <table class="min-w-full bg-white">-->
        <!--                <thead class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">-->
        <!--                <tr>-->
        <!--                  <th class="py-3 px-6 text-left">BalancePlatform</th>-->
        <!--                  <th class="py-3 px-6 text-left">AccountHolder</th>-->
        <!--                  <th class="py-3 px-6 text-left">BalanceAccount</th>-->
        <!--                  <th class="py-3 px-6 text-left">Transfer Id</th>-->
        <!--                  <th class="py-3 px-6 text-left">Transaction Id</th>-->
        <!--                  <th class="py-3 px-6 text-left">Category</th>-->
        <!--                  <th class="py-3 px-6 text-left">Status</th>-->
        <!--                  <th class="py-3 px-6 text-left">Type</th>-->
        <!--                  <th class="py-3 px-6 text-left">Booking Date</th>-->
        <!--                  <th class="py-3 px-6 text-left">TimeZone</th>-->
        <!--                  <th class="py-3 px-6 text-left">Value Date</th>-->
        <!--                  <th class="py-3 px-6 text-left">Currency</th>-->
        <!--                  <th class="py-3 px-6 text-left">Amount</th>-->
        <!--                  <th class="py-3 px-6 text-left">Reference</th>-->
        <!--                  <th class="py-3 px-6 text-left">Counterparty IBAN</th>-->
        <!--                  <th class="py-3 px-6 text-left">Counterparty BIC</th>-->
        <!--                  <th class="py-3 px-6 text-left">Counterparty Account Number</th>-->
        <!--                </tr>-->
        <!--                </thead>-->
        <!--                <tbody class="text-gray-600 text-sm font-light">-->
        <!--                <tr-->
        <!--                  v-for="(item, index) in transactions"-->
        <!--                  :key="index"-->
        <!--                  class="border-b border-gray-200 hover:bg-gray-100"-->
        <!--                >-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.BalancePlatform }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.AccountHolder }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.BalanceAccount }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.TransferId }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.TransactionId }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.Category }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.Status }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.Type }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.BookingDate }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.TimeZone }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.ValueDate }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.Currency }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.Amount }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.Reference }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.CounterpartyIban }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.CounterpartyBic }}</td>-->
        <!--                  <td class="py-3 px-6 text-left">{{ item.CounterpartyAccountNumber }}</td>-->
        <!--                </tr>-->
        <!--                </tbody>-->
        <!--              </table>-->
        <!--            </section>-->

        <!--            <section class="mt-6">-->
        <!--              <h2 class="text-xl font-bold mb-2">Statements</h2>-->
        <!--              <ul class="list-disc list-inside">-->
        <!--                <li v-for="(statement, index) in statements" :key="index" class="mb-1">-->
        <!--                  {{ statement }}-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </section>-->
        <!--          </div>-->
        <!--        </TabPanel>-->
      </TabView>
    </Card>

    <MiniModal :scrollable="true" :show-close-button="false" ref="payoutModal">
      <template #header> Withdraw money </template>
      <CounterpartyPayout
        v-if="balances"
        type="business_account"
        @successful="successfulPayout"
        :accounts="accounts"
        :balances="balances"
      ></CounterpartyPayout>
    </MiniModal>
  </card-section>
</template>

<script>
import Badge from 'primevue/badge'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import CounterpartyMixin from '../CounterpartyMixin'
import TransactionList from '../../transaction/TransactionList.vue'
import CounterpartyPayout from './CounterpartyPayout.vue'
import CounterpartyBalances from './CounterpartyBalances.vue'
import PayfacMixin from '../../PayfacMixin'

export default {
  name: 'BusinessAccountsActivity',
  props: {
    owners: {
      type: Array,
      default: () => []
    },
    accounts: {
      type: Array,
      default: () => []
    },
    merchant: {
      type: Object
    },
    counterparty: {
      type: Object
    }
  },
  data() {
    return {
      balances: [],
      payouts: null,
      payments: null,
      loading: true,
      payoutCurrentTab: 'paid',
      paymentCurrentTab: 'intent',
      autoPayouts: this.merchant && this.merchant.settings.payout_interval !== 'manual',
      payoutInterval: this.merchant.settings.payout_speed || 'regular',
      reload: false
    }
  },
  methods: {
    /**
     * When selecting a payment tab
     * @param {String} section
     * @param {String} tab
     */
    onSelect(section, tab) {
      this[section] = tab.key
    },
    /**
     * Open payout modal
     */
    createPayout() {
      this.$refs.payoutModal.open()
    },
    /**
     * After payout is successful reload account balances
     */
    async successfulPayout() {
      this.reload = true
      await this.loadBalances()
      this.$refs.payoutModal.close()
      this.reload = false
    },
    /**
     * Load more transactions
     * @param {String} type
     * @param {String} ref
     * @param {String|null} status
     */
    async loadMore(type, ref, status = null) {
      const current = this[ref]
      if (!current || !current.data) return
      const lastItem = current.data.slice(-1).pop()
      const startingAfter = lastItem.id
      this[ref].loading = true
      const { payload } = await this.fetchTransactions({
        type,
        starting_after: startingAfter
      })
      this[ref] = {
        ...current,
        has_more: payload.has_more,
        data: [...current.data, ...payload.data],
        loading: false,
        ...(status ? { status } : {})
      }
    },
    /**
     * Load the balances to display
     */
    async loadBalances() {
      try {
        this.loading = true
        const { payload } = await this.fetchBalances()
        this.balances = payload
        this.loading = false
      } catch (e) {
        console.log(e, 'e')
      }
    },
    /**
     * Get the class to display for the merchant status badge
     */
    getStatusClass(status) {
      let className = 'bg-dark'
      switch (status.toLowerCase()) {
        case 'active':
          className = '!bg-matcha-500'
          break
        case 'restricted':
          className = '!bg-deep-red-500'
          break
        case 'charges enabled':
          className = '!bg-yellow-700'
          break
        case 'pending payment':
          className = '!bg-yellow-700'
          break
        default:
          className = '!bg-surface-500'
      }
      return className
    }
  },
  computed: {
    balanceAccountIsReady() {
      return !!this.defaultBalanceAccount
    },
    /**
     * The payout schedule interval
     */
    interval() {
      return this.merchant.settings.payout_interval
    },
    /**
     * Available payment tabs
     */
    paymentTabs() {
      let tabs = [
        {
          key: 'intent',
          title: 'Attempts'
        },
        {
          key: 'charge',
          title: 'Charges'
        },
        {
          key: 'payout',
          title: 'Payouts'
        }
        // {
        //   key: 'businessAccountStatements',
        //   title: 'Business Account Statements'
        // }
      ]
      if (this.isPayfacEnabled) {
        tabs = [
          {
            key: 'intent',
            title: 'Payments'
          },
          {
            key: 'payout',
            title: 'Payouts'
          }
          // {
          //   key: 'businessAccountStatements',
          //   title: 'Business Account Statements'
          // }
        ]
      }
      return tabs.map((tab) => {
        const tabData = tab
        if (this[tab.key] && this[tab.key].data) {
          tabData.title = `${tab.title} (${this[tab.key].data.length}${this[tab.key].has_more ? '+' : ''})`
        }
        return tabData
      })
    },
    /**
     * Available payout tabs
     */
    payoutTabs() {
      const tabs = [
        {
          key: 'paid',
          title: 'Successful'
        },
        {
          key: 'payout',
          title: 'Needs attention'
        }
      ]
      return tabs.map((tab) => {
        const tabData = tab
        if (this[tab.key] && this[tab.key].data) {
          tabData.title = `${tab.title} (${this[tab.key].data.length}${this[tab.key].has_more ? '+' : ''})`
        }
        return tabData
      })
    },
    settlementTimes() {
      return [
        {
          text: 'Regular',
          value: 'regular'
        },
        {
          text: 'Fast',
          value: 'fast'
        },
        ...(this.counterpartyCountry === 'us'
          ? [
              {
                text: 'Faster',
                value: 'instant'
              }
            ]
          : [])
      ]
    },
    defaultBalance() {
      const payload = this.balances.filter(
        (account) => account.virtual_account_type === 'business_account'
      )
      return payload.length > 0 ? payload[0] : null
    },
    defaultBalanceAccount() {
      return this.defaultBalance ? this.defaultBalance.virtual_account_external_token : ''
    }
  },
  async mounted() {
    await this.loadBalances()
  },
  components: {
    TransactionList,
    CounterpartyPayout,
    CounterpartyBalances,
    Badge,
    TabView,
    TabPanel
  },
  mixins: [CounterpartyMixin, PayfacMixin]
}
</script>
