<template>
  <section class="counterparty-activity">
    <BusinessAccountsActivity
      v-if="merchant"
      :merchant="merchant"
      :counterparty="counterparty"
      :accounts="accounts"
      :owners="owners"
    />
  </section>
</template>

<script>
import CounterpartyMixin from '../CounterpartyMixin'
import BusinessAccountsActivity from './BusinessAccountsActivity.vue'

export default {
  name: 'BusinessAccountsActivities',
  props: {
    merchant: {
      type: Object
    },
    counterparty: {
      type: Object
    },
    accounts: {
      type: Array
    },
    owners: {
      type: Array
    }
  },
  data() {
    return {
      creatingAccount: false
    }
  },
  components: {
    BusinessAccountsActivity
  },
  mixins: [CounterpartyMixin]
}
</script>

<style lang="scss" rel="stylesheet/scss">
.counterparty-activity {
  .card-list-item {
    display: flex !important;
    flex-direction: row !important;
  }
  .loader {
    background: $cool-gray-400;
    width: 100%;
    height: 5rem;
  }
}
</style>
